<template>
    <r-e-drawer :modal-append-to-body="false" class="drawer-equipment-info" :title="drawerTitle"
                :visible.sync="drawerVisible" :size="1300"
                modal wrapper-closable drawer-wrapper >
        <div slot="title" class="drawer-header flex align-center">
            <div class="drawer-title">{{ drawerTitle }}</div>
        </div>
        <div class="drawer-container">
            <div class="drawer-container">
                <div class="container-header flex align-center">
                    <div class="item text-center" v-for="item in isArray"
                         :class="{ 'is-active-tab': item.name === activeTab }" :key="item.name"
                         @click="activeTab = item.name">
                        {{ item.label }}
                    </div>
                </div>
                <div class="container-content hidden-scrollbar">
                    <component :is="activeTab" :equipment-data="equipmentData" @handleSearch="handleSearch"
                        @closeDrawer="closeDrawer"/>
                </div>
            </div>
        </div>
    </r-e-drawer>
</template>

<script>
import {hydropower} from "@/api/door-management";

export default {
    name: "drawer-equipment-info",
    data() {
        return {
            houseTabOptions: [
                {label: "水电表", name: "surface"},
                {label: "门锁", name: "lock"}
            ],
            activeTab: "surface",
            drawerVisible: false,
            drawerTitle: "设备",
            equipmentData: {},
            isShowSurface: true,
            isArray: []
        };
    },
    components: {
        surface: () => import("@/views/property-management/door-management/components/part/surface"),
        lock: () => import("@/views/property-management/door-management/components/part/lock"),
    },
    methods: {
      // pickerclick(){
      //   console.log('003333333333333300')
      // },
        async openDrawer(row) {
            const {apartmentUuid: uuid, apartmentName: name} = row;
            // 判断是否有电表号在进行渲染
            if (row.waterNum === null && row.electNum === null) {
              // alert('有电表')
                this.activeTab = "lock";
                this.isArray = this.houseTabOptions.filter((item, index) => index !== 0);
            } else {
              // alert('没电表')
                this.isArray = this.houseTabOptions;
                this.activeTab = "surface";
                await this.hydropower(uuid);
            }
            this.drawerTitle = name;
            this.equipmentData = {...row, uuid, name,waterState:this.equipmentData.waterState,electState:this.equipmentData.electState};
            this.drawerVisible = true;
        },
        async hydropower(uuid) {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let res = await hydropower(uuid).catch(err => {
              console.log(err)
            });
            if (res) {
                let {
                    info: {
                        waterMeter,
                        electricityMeter,
                        electState,
                        waterState,
                        waterStatus,
                        electricityStatus
                    }
                } = res;
                this.equipmentData.electricityMeter = electricityMeter;
                this.equipmentData.waterMeter = waterMeter;
                this.equipmentData.electState = electState;
                this.equipmentData.waterState = waterState;
                this.equipmentData.waterStatus = waterStatus;
                this.equipmentData.electStatus = electricityStatus;
                console.log('this.equipmentData.electricityMeter',this.equipmentData.electricityMeter);
                console.log('this.equipmentData',this.equipmentData);
            }
            loading.close();
        },
        handleSearch() {
            this.$emit('handleSearch', false);
        },
        closeDrawer() {
            this.handleSearch();
            this.drawerVisible = false;
        },
    }
}
</script>

<style lang="scss" scoped>
.drawer-equipment-info {
    .drawer-header {
        .room-status {
            color: #1F7CF6;
            font-size: rem(14px);
            border-radius: 15px;
            padding: VH(3px) VW(10px);
            background: #CEE7FF;
            margin-left: VW(10px);
        }
    }

    .drawer-container {
        margin-left: VW(5px);

        .container-header {
            width: fit-content;
            border-radius: 4px;
            box-shadow: 0 6px 12px rgba(0, 45, 170, 0.16);

            .item {
                padding: VH(15px) VW(30px);
                color: #666;
                font-size: rem(16px);
                cursor: pointer;
                border-radius: 4px;
                margin-right: VW(5px);

                &:last-child {
                    margin-right: 0;
                }

                &.is-active-tab,
                &:hover {
                    color: #fff;
                    background: linear-gradient(80deg, #A8D4FF 0%, #2386F6 100%);
                    box-shadow: 0 6px 12px rgba(0, 45, 170, 0.16);
                }
            }
        }

        .container-content {
            height: VH(870px);
            margin-top: VH(20px);
        }
    }

    /deep/ .container-content {
        .table-title {
            position: relative;
            padding-left: 10px;
            margin: 20px 0;

            &::before {
                width: 5px;
                height: 100%;
                content: '';
                background-color: #F5A623;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
}
</style>
